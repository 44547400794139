import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { childrenWrapper, errorBox, loader } from './user-order.module.scss';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { getNodes } from '../../utils/get-nodes';
import { useOrder } from '../../hooks/use-order';

import UserLayout from '../../layouts/user-layout';
import HistoryItemHeader from '../molecules/history-item-header';
import Loader from '../atoms/loader';
import HistoryItemDetails from '../organisms/history-item-details';

export interface IUserOrderSection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IUserOrderProps {
    className?: string;
    section: IUserOrderSection;
    TitleTag?: React.ElementType;
}

interface IUserOrderStaticQueryResult {
    allOrderHistoryPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const UserOrder = ({ section, className = '', TitleTag = 'h2' }: IUserOrderProps) => {
    const { locale } = usePageContext();
    const { allOrderHistoryPage } = useStaticQuery<IUserOrderStaticQueryResult>(query);
    const orderHistoryPage = getNodes(allOrderHistoryPage).find((page) => page.locale === locale);

    const { sectionId, css, style } = section;
    const [subtitle] = section.content.texts;
    const { links } = section.items;

    const orderState = useOrder({ queries: ['single'] });
    const order = orderState.single.data;
    const redirectUrl = orderState.retryPayment.data?.redirectUrl;

    useEffect(() => {
        if (redirectUrl) {
            window.location.replace(redirectUrl);
        }
    }, [redirectUrl]);

    const handleRetry = async (paymentId: string) => {
        orderState.retryPayment.fetch(paymentId);
    };

    return (
        <UserLayout
            sectionId={sectionId}
            className={className}
            links={links}
            childrenWrapperClassName={childrenWrapper}
            css={css}
            style={style}
        >
            {orderState.single.isLoading && <Loader className={loader} />}
            {!orderState.single.isLoading && (
                <>
                    {order && (
                        <HistoryItemHeader
                            title={`${subtitle} ${order?.uid}`}
                            returnPath={
                                order.isSubscription
                                    ? `${orderHistoryPage?.pathname}?tab=subscriptions`
                                    : orderHistoryPage?.pathname
                            }
                            item={order}
                            TitleTag={TitleTag}
                        />
                    )}
                    {orderState.single.isError && (
                        <div className={errorBox}>
                            {orderState.single.errors?.map((error) => {
                                return <p>{error.content}</p>;
                            })}
                        </div>
                    )}
                    {orderState.single.isSuccess && order && (
                        <HistoryItemDetails item={order} onRetry={handleRetry} />
                    )}
                </>
            )}
        </UserLayout>
    );
};

export default UserOrder;

const query = graphql`
    query {
        allOrderHistoryPage: allPage(filter: { type: { eq: "user-orders" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;
